
.wrap {
  max-width: 1000px;
  margin: 100px auto;
  padding: 0 25px;
}

:root {
  --amplify-components-authenticator-modal-background-color: white;
}

:global {
  .amplify-tabs {
    display: none;
  }
}