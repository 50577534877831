
$overlay: rgba(black, 0.85);

$green: #3c6;

$water: #0ac;
$overdue: #f80;
$defer: #66c;
$done: #3c6;
$disabled: #9aa;
$edit: #f80;
