
.wrap {

  label {
    display: inline-block;
    text-align: right;
    width: 150px;
    padding-right: 15px;
  }

}
