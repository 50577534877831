@use 'styles/colors';

.wrap {

  .wrapTimeline {
    position: relative;

    .plant {
      h4 {
        position: relative;
        z-index: 10;
      }

      .timeline {
        position: relative;
        height: 15px;

        .line {
          position: absolute;
          top: 50%;
          height: 3px;
          transform: translate(0, -50%);
          background-color: colors.$water;
        }
        .pt {
          position: absolute;
          top: 50%;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: #ccc;

          &.watering {
            background-color: colors.$water;
          }
        }

      }
    }

    .wrapCalendar {

      .line {
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e3e3e3;

        &.week {
          width: 2px;
          background-color: #ccc;
        }

      }
    }

  }

}
