@use 'styles/colors';
@use 'styles/zIndexes';

.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: zIndexes.$header;
  max-width: 100vw;

  background: colors.$green;
  padding: 20px 25px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.025);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  h1 {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 0;
    letter-spacing: 0.075em;
    a {
      text-decoration: none;
      color: white;
    }
  }

  /*.wrapMenu {
    flex-grow: 1;

    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.075em;
        font-weight: 500;

        & + li {
          margin-left: 10px;
        }
        a {
          text-decoration: none;
        }
        &.selected {
          font-weight: 700;
          a {
            pointer-events: none;
            text-decoration: none;
            color: black;
          }
        }
      }
    }
  } */

  .wrapUserMenu {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;

    .wrapProfileImg {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      overflow: hidden;
      border-radius: 50%;
      background-color: #eee;

      img.profileImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    ul.userMenu {
      position: absolute;
      list-style: none;
      margin: 0;
      padding: 0;
      right: 0;
      top: calc(100% + 5px);
      background-color: white;
      border: 1px solid rgb(0 0 0 / 0.1);
      border-radius: 5px;

      &:not(.open) {
        display: none;
      }

      li {
        white-space: nowrap;

        a {
          display: block;
          padding: 10px 20px 10px 15px;
          text-decoration: none;
          color: #333;
        }

        & + li {
          border-top: 1px solid rgb(0 0 0 / 0.1);
        }
      }
    }

  }

}
