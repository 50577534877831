@use 'sass:color';
@use 'styles/colors';

.wrap {
  position: relative;

  .wrapAdd {
    position: fixed;
    bottom: 20px;
    right: 20px;

    a {
      $size: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $size;
      height: $size;
      border-radius: 50%;
      font-size: 50px;
      text-decoration: none;
      background-color: colors.$green;
      color: white;
      box-shadow:
        3px 5px 10px rgb(0 0 0 / 0.1),
        2px 2px 5px rgb(0 100 0 / 0.1);

      &:active {
        background-color: color.adjust(colors.$green, $lightness: -10%);
      }
    }
  }

  h2,
  ul.roomFilter {
    display: none; // TODO
  }

  ul.roomFilter {
    list-style: none;
    margin: 0 0 25px 0;
    padding: 0;

    li {
      display: inline-block;
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 25px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      & + li {
        margin-left: 5px;
      }

      &.selected {
        cursor: auto;
        background-color: colors.$green;
        color: white;
      }

      &:not(.selected):hover {
        background-color: rgba(colors.$green, 0.25);
      }

    }
  }

  ul.plants {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    > li {
      display: inline-block;
      flex-basis: 320px;
    }

  }

}
