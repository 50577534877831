
@use 'styles/zIndexes';
@use 'styles/colors';

.wrap {
  z-index: zIndexes.$modal;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: colors.$overlay;

  .panel {
    width: 750px;
    padding: 30px;
    background-color: white;

    footer {
      text-align: right;

      button + button {
        margin-left: 10px;
      }
    }

  }

}
