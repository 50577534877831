@use 'sass:color';
@use 'styles/colors';
@use 'styles/fonts';

.wrap {

  border: 2px solid rgba(black, 0.1);
  padding: 20px;
  border-radius: 10px;

  color: rgba(black, 0.65);

  strong {
    color: black;
  }

  em {
    opacity: 0.75;
  }

  .editBtn {
    display: inline-block;
    padding: 0 4px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    text-decoration: none;
    color: colors.$edit;
    border: 1px solid colors.$edit;
    border-radius: 3px;
    margin-left: 10px;
    vertical-align: 2px;
    &:active {
      color: white;
      background-color: colors.$edit;
    }
  }

  &.dueToday {
    border-color: colors.$water;
    .nextWaterDate {
      font-weight: bold;
      color: colors.$water;
    }
  }

  &.overdue {
    border-color: colors.$overdue;
    .nextWaterDate {
      font-weight: bold;
      color: colors.$overdue;
    }
  }

  &.wateredToday {
    border-color: colors.$done;
    .lastWaterDate {
      font-weight: bold;
      color: colors.$done;
    }
  }

  img.plantImg {
    width: 100%;
    object-fit: cover;
    height: 200px;
  }

  .wrapActions {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      display: block;
      flex-grow: 1;
      flex-basis: 50%;

      padding: 10px 0;
      border: 2px solid transparent;
      border-radius: 7px;

      appearance: none;
      font-family: fonts.$sans;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.075em;

      i {
        display: inline-block;
        font-size: 15px;
        line-height: 0;
        vertical-align: -3px;
      }

      &.waterBtn {
        border-color: colors.$water;
        color: colors.$water;

        &:active {
          background-color: colors.$water;
          color: white;
        }

      }

      &.deferBtn {
        border-color: colors.$defer;
        color: colors.$defer;

        &:active {
          background-color: colors.$defer;
          color: white;
        }
      }

      &[disabled] {
        pointer-events: none;
        opacity: 0.5;
        color: colors.$disabled;
        border-color: colors.$disabled
      }

    }

  }

}
